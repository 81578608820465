import {required, maxLength, minLength, numeric} from 'vuelidate/lib/validators'

export default {
  product: {
    sku: {
      required,
      minLength: minLength(2)
    },
    description: {
      required,
      minLength: minLength(2)
    },
    oem: {
      required,
      minLength: minLength(2)
    },
    pieces: {
      required,
      numeric
    },
    category_id: {
      required
    },
    external_diameter_1: {
      minLength: minLength(2)
    },
    external_diameter_2: {
      minLength: minLength(2)
    },
    internal_diameter_1: {
      minLength: minLength(2)
    },
    internal_diameter_2: {
      minLength: minLength(2)
    },
    image: {
      minLength: minLength(2)
    },
    height: {
      minLength: minLength(2)
    },
    width: {
      minLength: minLength(2)
    },
    length: {
      minLength: minLength(2)
    },
    upc: {
      minLength: minLength(2)
    },
    micron: {
      minLength: minLength(2)
    },
    connections: {
      minLength: minLength(2)
    },
    standpipe: {
      minLength: minLength(2)
    }
  }
}
