<template>
  <div class="md-layout margin-fix">
    <div class="md-layout-item">
      <Edit :categories="categories" :prod="product" />
    </div>
  </div>
</template>

<script>
import Edit from '@/components/Products/Edit.vue'
import { mapGetters, mapActions } from 'vuex'
import auth from '@/mixins/auth'
export default {
  name: 'EditProduct',
  mixins: [auth],
  components: {
    Edit
  },
  mounted() {
    this.prod(this.$route.params.id)
    this.cats()
  },
  computed: {
    ...mapGetters({categories: 'categories/listCategories', product: 'products/listProduct'})
  },
  methods: {
    ...mapActions({cats: 'categories/getProductCategories', prod: 'products/getProduct'})
  }
}
</script>
